module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T7B761KJQ0"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gudang Seragam","short_name":"Gudang Seragam","start_url":"/","icon":"static/favicon.png","icons":[{"src":"static/192.png","type":"image/png","sizes":"192x192"},{"src":"static/512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f77f10693d28a84e22633c3921690051"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
