// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-backup-form-tsx": () => import("./../../../src/pages/backup-form.tsx" /* webpackChunkName: "component---src-pages-backup-form-tsx" */),
  "component---src-pages-bahan-tsx": () => import("./../../../src/pages/bahan.tsx" /* webpackChunkName: "component---src-pages-bahan-tsx" */),
  "component---src-pages-cara-order-tsx": () => import("./../../../src/pages/cara-order.tsx" /* webpackChunkName: "component---src-pages-cara-order-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contoh-desain-tsx": () => import("./../../../src/pages/contoh-desain.tsx" /* webpackChunkName: "component---src-pages-contoh-desain-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jaket-tsx": () => import("./../../../src/pages/jaket.tsx" /* webpackChunkName: "component---src-pages-jaket-tsx" */),
  "component---src-pages-klien-kami-tsx": () => import("./../../../src/pages/klien-kami.tsx" /* webpackChunkName: "component---src-pages-klien-kami-tsx" */),
  "component---src-pages-layanan-gratis-tsx": () => import("./../../../src/pages/layanan-gratis.tsx" /* webpackChunkName: "component---src-pages-layanan-gratis-tsx" */),
  "component---src-pages-polo-tsx": () => import("./../../../src/pages/polo.tsx" /* webpackChunkName: "component---src-pages-polo-tsx" */),
  "component---src-pages-portofolio-tsx": () => import("./../../../src/pages/portofolio.tsx" /* webpackChunkName: "component---src-pages-portofolio-tsx" */),
  "component---src-pages-rompi-tsx": () => import("./../../../src/pages/rompi.tsx" /* webpackChunkName: "component---src-pages-rompi-tsx" */),
  "component---src-pages-standar-ukuran-tsx": () => import("./../../../src/pages/standar-ukuran.tsx" /* webpackChunkName: "component---src-pages-standar-ukuran-tsx" */),
  "component---src-pages-tentang-kami-tsx": () => import("./../../../src/pages/tentang-kami.tsx" /* webpackChunkName: "component---src-pages-tentang-kami-tsx" */),
  "component---src-pages-wearpack-tsx": () => import("./../../../src/pages/wearpack.tsx" /* webpackChunkName: "component---src-pages-wearpack-tsx" */)
}

